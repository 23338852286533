/* @font-face {
	font-family: "Roboto";
	src: url(https://www.psb-academy.edu.sg/fonts/Roboto/Roboto-Regular.ttf);
	font-weight: 400;
}
@font-face {
	font-family: "Roboto";
	src: url(https://www.psb-academy.edu.sg/fonts/Roboto/Roboto-Italic.ttf);
	font-style: italic;
	font-weight: 400;
}
@font-face {
	font-family: "Roboto";
	src: url(https://www.psb-academy.edu.sg/fonts/Roboto/Roboto-Bold.ttf);
	font-weight: 700;
}
@font-face {
	font-family: "Roboto";
	src: url(https://www.psb-academy.edu.sg/fonts/Roboto/Roboto-Medium.ttf);
	font-weight: 500;
}
@font-face {
	font-family: "Roboto";
	src: url(https://www.psb-academy.edu.sg/fonts/Roboto/Roboto-MediumItalic.ttf);
	font-style: italic;
	font-weight: 500;
}
@font-face {
	font-family: "Roboto";
	src: url(https://www.psb-academy.edu.sg/fonts/Roboto/Roboto-BoldItalic.ttf);
	font-style: italic;
	font-weight: 700;
}
@font-face {
	font-family: "Oswald";
	src: url(https://www.psb-academy.edu.sg/fonts/Oswald/Oswald-Regular.ttf);
	font-weight: 400;
}
@font-face {
	font-family: "Oswald";
	src: url(https://www.psb-academy.edu.sg/fonts/Oswald/Oswald-Bold.ttf);
	font-weight: 700;
} */
@font-face {
	font-family: "Montserrat";
	src: local('Montserrat'), url(./fonts/Montserrat-Regular.ttf);
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "Montserrat";
	src: local('Montserrat'),url(./fonts/Montserrat-Bold.ttf);
	font-weight: 700;
	font-display: swap;
}
@font-face {
	font-family: "Montserrat";
	src: local('Montserrat'),url(./fonts/Montserrat-Medium.ttf);
	font-weight: 500;
	font-display: swap;
}
/* @font-face {
	font-family: "Montserrat";
	src: url(https://www.psb-academy.edu.sg/fonts/Montserrat/Montserrat-Italic.ttf);
	font-style: italic;
	font-weight: 400;
	font-display: swap;
} */
/* @font-face {
	font-family: "Montserrat";
	src: url(https://www.psb-academy.edu.sg/fonts/Montserrat/Montserrat-MediumItalic.ttf);
	font-style: italic;
	font-weight: 500;
	font-display: swap;
}
@font-face {
	font-family: "Montserrat";
	src: url(https://www.psb-academy.edu.sg/fonts/Montserrat/Montserrat-BoldItalic.ttf);
	font-style: italic;
	font-weight: 700;
	font-display: swap;
} */

body,
html {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

input {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.btn-main, .btn-main:hover, .btn-main:active, .btn-main:visited {
    background-color: #b30537 !important;
	border-color: #b30537 !important;
	
    color: #fff !important;
    padding: 7px 25px !important;
}
header img {
	width: 50px;
	height: 50px;
}

.answer-option-img {
	width: 100px;
	height: 100px;
}

.answer-choice-icon {
	height: 50px;
	width: 50px;
	margin-right: 2%;
	border-radius: 24px;
}
.wrapper {
	background: url("assets/quiz-image.jpg")
		no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100%;
}

.counter {
	font-size: 138px;
}

.logo {
	height: 48px;
}
.nav-custom {
  background: rgba(0,0,0,.5);
}

.black-nav {
	background: #000;
}
.nav-item {
	padding-inline: 45px;
}
.nav-link {
	color: white;
}

.landing-page {
	background: url(https://www.psb-academy.edu.sg/wordpress/wp-content/uploads-webpc/uploads/2021/06/Events-Activities-Inner-Page-Desktop.jpg.webp);
	height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
  margin-top: -70px;
}

.question-text {
	/* font-size: 40px; */
}

.answer-option {
	font-size: 20px;
}

.enquire-now {
	font-weight: 700;
    text-decoration: none;
    color: #b30537;
    cursor: pointer;
    border: 2px solid;
    padding: 7px;
    margin: 5px;
    line-height: 2;
	border-radius: 12px;
}

.box{
	width:100%;
	height:100%;
	display:flex;
	justify-content: space-between;
	align-items:start;
	flex-direction:column;
	color:rgb(9, 39, 121);
  }

  .text-justify {
	text-align: justify;
  }